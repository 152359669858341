<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17.75C11.3206 17.75 13.5462 16.8281 15.1872 15.1872C16.8281 13.5462 17.75 11.3206 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25C6.67936 0.25 4.45376 1.17187 2.81282 2.81282C1.17187 4.45376 0.25 6.67936 0.25 9C0.25 11.3206 1.17187 13.5462 2.81282 15.1872C4.45376 16.8281 6.67936 17.75 9 17.75ZM10.25 5.875C10.25 6.20652 10.1183 6.52446 9.88388 6.75888C9.64946 6.9933 9.33152 7.125 9 7.125C8.66848 7.125 8.35054 6.9933 8.11612 6.75888C7.8817 6.52446 7.75 6.20652 7.75 5.875C7.75 5.54348 7.8817 5.22554 8.11612 4.99112C8.35054 4.7567 8.66848 4.625 9 4.625C9.33152 4.625 9.64946 4.7567 9.88388 4.99112C10.1183 5.22554 10.25 5.54348 10.25 5.875ZM9 8.6875C9.24864 8.6875 9.4871 8.78627 9.66291 8.96209C9.83873 9.1379 9.9375 9.37636 9.9375 9.625V12.75C9.9375 12.9986 9.83873 13.2371 9.66291 13.4129C9.4871 13.5887 9.24864 13.6875 9 13.6875C8.75136 13.6875 8.5129 13.5887 8.33709 13.4129C8.16127 13.2371 8.0625 12.9986 8.0625 12.75V9.625C8.0625 9.37636 8.16127 9.1379 8.33709 8.96209C8.5129 8.78627 8.75136 8.6875 9 8.6875Z" :fill="fill"/>
  </svg>
</template>

<script>
  export default {
    name: "AlertIcon",
    props: {
      width: {
        type: Number,
        default: 18,
      },
      height: {
        type: Number,
        default: 18,
      },
      fill: {
        type: String,
        default: "#2671D9"
      }
    }
  }
</script>