var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"32px 30px 62px 33px"}},[_c('div',{staticStyle:{"background-color":"#ffffff","padding":"44px 32px 52px 32px","border-radius":"14px","display":"flex","flex-direction":"column","gap":"24px"}},[_c('div',{staticStyle:{"font-weight":"600","font-size":"22px"}},[_vm._v("Revenue (IDR)")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"30px"}},[_c('div',{staticStyle:{"width":"70%"}},[(_vm.notification)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row","background-color":"#e9f1fb","height":"60px","gap":"10px","padding":"16px","border-radius":"6px"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('AlertIcon',{attrs:{"width":"18","height":"18","fill":"#2671D9"}})],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between","width":"100%","color":"#575966","font-size":"14px"}},[_c('div',{staticStyle:{"font-size":"86%","display":"flex","justify-content":"center","align-items":"center"}},[_vm._v(" Refresh terlebih dahulu halaman ini untuk memperbarui data revenue ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","cursor":"pointer"},attrs:{"title":"close notification"},on:{"click":function($event){_vm.notification = !_vm.notification}}},[_c('Close',{staticStyle:{"color":"#acafbf"},attrs:{"width":"20","height":"20"}})],1)])]):_vm._e(),_c('div',{style:({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: _vm.notification ? '32px' : '15px',
          })},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.businessCategory),function(data,index){return _c('div',{key:index,style:({
                backgroundColor:
                  _vm.indexBusinessButton === index ? '#2D46CF' : '#F5F6FA',
                borderRadius:
                  index === 0
                    ? '4px 0px 0px 4px'
                    : index === 3
                    ? '0px 4px 4px 0px'
                    : '0px',
                color: _vm.indexBusinessButton === index ? '#FFFFFF' : '#7B7E8C',
                display: 'flex',
                justifyContent: 'center',
                width: '70px',
                fontSize: '14px',
                fontWeight: '500',
                padding: '6px',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.clickBusinessButton(index)}}},[_vm._v(" "+_vm._s(data)+" ")])}),0),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.monthYearCategory),function(data,index){return _c('div',{key:index,style:({
                backgroundColor:
                  _vm.indexMonthYearButton === index ? '#2D46CF' : '#F5F6FA',
                color: _vm.indexMonthYearButton === index ? '#FFFFFF' : '#7B7E8C',
                borderRadius:
                  index === 0
                    ? '4px 0px 0px 4px'
                    : index === 1
                    ? '0px 4px 4px 0px'
                    : '0px',
                display: 'flex',
                justifyContent: 'center',
                width: '70px',
                fontSize: '14px',
                fontWeight: '400px',
                padding: '6px',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.clickMonthYearButton(index)}}},[_vm._v(" "+_vm._s(data)+" ")])}),0)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"28px","margin-top":"18px"}},_vm._l((_vm.displayedData),function(_,index){return _c('div',{key:index,staticStyle:{"border":"1px solid #d3d6e1","border-radius":"10px","margin-top":"12px","width":"50%","height":"130px","padding":"70px 20px","display":"flex","flex-direction":"column","justify-content":"center","gap":"8px"}},[_c('div',{staticStyle:{"font-size":"16px","color":"#575966","font-weight":"500"}},[_vm._v(" Total "+_vm._s(_vm.currentIncome.month)+" Income ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","margin-top":"2px","gap":"8px"}},[_c('div',{staticStyle:{"font-size":"24px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.currentIncome.total)+" ")]),_c('div',{style:({
                  backgroundColor:
                    _vm.displayedData[index].percentage <= 0
                      ? '#FFDEDE'
                      : '#E2FCF3',
                  color:
                    _vm.displayedData[index].percentage <= 0
                      ? '#FF5656'
                      : '#0EA976',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  width: '68px',
                  height: '28px',
                  fontSize: '14px',
                  gap: '4px',
                  padding: '0px 30px',
                })},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('Arrow2',{attrs:{"fill":_vm.getArrowColor(_vm.displayedData[index].percentage),"direct":_vm.getArrowDirect(_vm.displayedData[index].percentage)}})],1),_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.removeMinSymbol(_vm.displayedData[index].percentage))+"% ")])])]),_c('div',{staticStyle:{"color":"#4e4e4e","font-size":"13px","font-weight":"400"}},[_vm._v(" "+_vm._s(index === 0 && _vm.indexBusinessButton === 0 ? "All Program" : index === 0 && _vm.indexBusinessButton !== 0 ? `All ${_vm.businessCategory[_vm.indexBusinessButton]} Program` : index === 1 && _vm.indexMonthYearButton === 0 ? `In ${new Date().getFullYear()}` : "In 5 years")+" ")])])}),0),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('div',{staticStyle:{"color":"#575966","font-size":"16px"}},[_vm._v("Income Per Sector")]),_c('div',{staticStyle:{"margin-top":"16px"}},[_c('VueApexCharts',{ref:"realtimeChart",attrs:{"height":"330","options":_vm.chartOptions,"series":_vm.series}})],1)])]),_c('div',{staticStyle:{"width":"30%","display":"flex","flex-direction":"column","gap":"14px"}},_vm._l((_vm.displayedClassData),function(_,index){return _c('div',{key:index,staticStyle:{"border":"1px solid #d3d6e1","border-radius":"10px","width":"100%","height":"130px","padding":"70px 20px","display":"flex","flex-direction":"column","justify-content":"center","gap":"8px"}},[_c('div',{staticStyle:{"font-size":"16px","color":"#575966","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.displayedClassData[index].title)+" ")]),_c('div',{staticStyle:{"margin-top":"2px","gap":"8px","display":"flex","flex-direction":"row","align-items":"center"}},[_c('div',{staticStyle:{"font-size":"24px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.displayedClassData[index].total))+"K ")]),_c('div',{style:({
                backgroundColor:
                  _vm.displayedClassData[index].percentage <= 0
                    ? '#FFDEDE'
                    : '#E2FCF3',
                color:
                  _vm.displayedClassData[index].percentage <= 0
                    ? '#FF5656'
                    : '#0EA976',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                width: '68px',
                height: '28px',
                fontSize: '14px',
                gap: '4px',
                padding: '0px 30px',
              })},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('Arrow2',{attrs:{"fill":_vm.getArrowColor(_vm.displayedClassData[index].percentage),"direct":_vm.getArrowDirect(_vm.displayedClassData[index].percentage)}})],1),_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.removeMinSymbol(_vm.displayedClassData[index].percentage))+"% ")])])]),_c('div',{staticStyle:{"color":"#4e4e4e","font-size":"13px","font-weight":"400"}},[_vm._v(" Total Revenue of "+_vm._s(_vm.indexMonthYearButton === 0 ? _vm.getCurrentMonth : new Date().getFullYear())+" ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }