<template>
  <div style="padding: 32px 30px 62px 33px">
    <!-- Container -->
    <div
      style="
        background-color: #ffffff;
        padding: 44px 32px 52px 32px;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      "
    >
      <!-- Title -->
      <div style="font-weight: 600; font-size: 22px">Revenue (IDR)</div>
      <!-- Content -->
      <div style="display: flex; flex-direction: row; gap: 30px">
        <!-- Column 1 -->
        <div style="width: 70%">
          <!-- Pop-up Notification -->
          <div
            v-if="notification"
            style="
              display: flex;
              flex-direction: row;
              background-color: #e9f1fb;
              height: 60px;
              gap: 10px;
              padding: 16px;
              border-radius: 6px;
            "
          >
            <!-- Icon -->
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <AlertIcon width="18" height="18" fill="#2671D9" />
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                color: #575966;
                font-size: 14px;
              "
            >
              <!-- Description -->
              <div
                style="
                  font-size: 86%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                Refresh terlebih dahulu halaman ini untuk memperbarui data
                revenue
              </div>
              <!-- Close -->
              <div
                @click="notification = !notification"
                title="close notification"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                "
              >
                <Close width="20" height="20" style="color: #acafbf" />
              </div>
            </div>
          </div>
          <!-- Select Chart Button -->
          <div
            :style="{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: notification ? '32px' : '15px',
            }"
          >
            <!-- Business Category -->
            <div style="display: flex; flex-direction: row">
              <div
                v-for="(data, index) in businessCategory"
                :key="index"
                @click="clickBusinessButton(index)"
                :style="{
                  backgroundColor:
                    indexBusinessButton === index ? '#2D46CF' : '#F5F6FA',
                  borderRadius:
                    index === 0
                      ? '4px 0px 0px 4px'
                      : index === 3
                      ? '0px 4px 4px 0px'
                      : '0px',
                  color: indexBusinessButton === index ? '#FFFFFF' : '#7B7E8C',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '70px',
                  fontSize: '14px',
                  fontWeight: '500',
                  padding: '6px',
                  cursor: 'pointer',
                }"
              >
                {{ data }}
              </div>
            </div>
            <!-- Month/Year Category -->
            <div style="display: flex; flex-direction: row">
              <div
                v-for="(data, index) in monthYearCategory"
                :key="index"
                @click="clickMonthYearButton(index)"
                :style="{
                  backgroundColor:
                    indexMonthYearButton === index ? '#2D46CF' : '#F5F6FA',
                  color: indexMonthYearButton === index ? '#FFFFFF' : '#7B7E8C',
                  borderRadius:
                    index === 0
                      ? '4px 0px 0px 4px'
                      : index === 1
                      ? '0px 4px 4px 0px'
                      : '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '70px',
                  fontSize: '14px',
                  fontWeight: '400px',
                  padding: '6px',
                  cursor: 'pointer',
                }"
              >
                {{ data }}
              </div>
            </div>
          </div>
          <!-- Income -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 28px;
              margin-top: 18px;
            "
          >
            <div
              v-for="(_, index) in displayedData"
              :key="index"
              style="
                border: 1px solid #d3d6e1;
                border-radius: 10px;
                margin-top: 12px;
                width: 50%;
                height: 130px;
                padding: 70px 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
              "
            >
              <!-- Title -->
              <div style="font-size: 16px; color: #575966; font-weight: 500">
                <!-- {{
                  index === 0 && indexMonthYearButton == 0
                    ? `Total ${getCurrentMonth} Income`
                    : index === 0 && indexMonthYearButton == 1
                    ? `Total ${new Date().getFullYear()} Income`
                    : index === 1 && indexBusinessButton == 0
                    ? "Avg. Monthly Income"
                    : index === 1
                    ? `Avg. ${businessCategory[indexBusinessButton]} Income`
                    : ""
                }} -->
                Total {{ currentIncome.month }} Income
              </div>
              <!-- Total -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 2px;
                  gap: 8px;
                "
              >
                <!-- Price -->
                <div style="font-size: 24px; font-weight: 600">
                  {{ currentIncome.total }}
                </div>
                <!-- Percentage -->
                <div
                  :style="{
                    backgroundColor:
                      displayedData[index].percentage <= 0
                        ? '#FFDEDE'
                        : '#E2FCF3',
                    color:
                      displayedData[index].percentage <= 0
                        ? '#FF5656'
                        : '#0EA976',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '12px',
                    width: '68px',
                    height: '28px',
                    fontSize: '14px',
                    gap: '4px',
                    padding: '0px 30px',
                  }"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <Arrow2
                      :fill="getArrowColor(displayedData[index].percentage)"
                      :direct="getArrowDirect(displayedData[index].percentage)"
                    />
                  </div>
                  <div style="font-weight: 600">
                    {{ removeMinSymbol(displayedData[index].percentage) }}%
                  </div>
                </div>
              </div>
              <!-- Description -->
              <div style="color: #4e4e4e; font-size: 13px; font-weight: 400">
                {{
                  index === 0 && indexBusinessButton === 0
                    ? "All Program"
                    : index === 0 && indexBusinessButton !== 0
                    ? `All ${businessCategory[indexBusinessButton]} Program`
                    : index === 1 && indexMonthYearButton === 0
                    ? `In ${new Date().getFullYear()}`
                    : "In 5 years"
                }}
              </div>
            </div>
          </div>
          <!-- Chart -->
          <div style="margin-top: 30px">
            <!-- Title -->
            <div style="color: #575966; font-size: 16px">Income Per Sector</div>
            <!-- Chart -->
            <div style="margin-top: 16px">
              <VueApexCharts
                ref="realtimeChart"
                height="330"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </div>
        </div>
        <!-- Column 2 -->
        <div
          style="width: 30%; display: flex; flex-direction: column; gap: 14px"
        >
          <!-- Income -->
          <div
            v-for="(_, index) in displayedClassData"
            :key="index"
            style="
              border: 1px solid #d3d6e1;
              border-radius: 10px;
              width: 100%;
              height: 130px;
              padding: 70px 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
            "
          >
            <!-- Title -->
            <div style="font-size: 16px; color: #575966; font-weight: 500">
              {{ displayedClassData[index].title }}
            </div>
            <!-- Total -->
            <div
              style="
                margin-top: 2px;
                gap: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
              "
            >
              <!-- Price -->
              <div style="font-size: 24px; font-weight: 600">
                {{ formatPrice(displayedClassData[index].total) }}K
              </div>
              <!-- Percentage -->
              <div
                :style="{
                  backgroundColor:
                    displayedClassData[index].percentage <= 0
                      ? '#FFDEDE'
                      : '#E2FCF3',
                  color:
                    displayedClassData[index].percentage <= 0
                      ? '#FF5656'
                      : '#0EA976',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  width: '68px',
                  height: '28px',
                  fontSize: '14px',
                  gap: '4px',
                  padding: '0px 30px',
                }"
              >
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <Arrow2
                    :fill="getArrowColor(displayedClassData[index].percentage)"
                    :direct="
                      getArrowDirect(displayedClassData[index].percentage)
                    "
                  />
                </div>
                <div style="font-weight: 600">
                  {{ removeMinSymbol(displayedClassData[index].percentage) }}%
                </div>
              </div>
            </div>
            <!-- Description -->
            <div style="color: #4e4e4e; font-size: 13px; font-weight: 400">
              Total Revenue of
              {{
                indexMonthYearButton === 0
                  ? getCurrentMonth
                  : new Date().getFullYear()
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertIcon from "@/components/icons/Alert.vue";
import Arrow2 from "@/components/icons/Arrow2.vue";
import Close from "@/components/icons/Close.vue";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "FinanceStatistic",
  components: {
    AlertIcon,
    Close,
    Arrow2,
    VueApexCharts,
  },
  data() {
    return {
      notification: true,
      indexBusinessButton: 0,
      indexMonthYearButton: 0,
      businessCategory: ["All", "B2C", "B2B", "B2G"],
      monthYearCategory: ["Month", "Year"],
      currentIncome: {
        month: "",
        total: "",
      },
      AllTotalData: [
        {
          id: 1,
          total: 3872,
          percentage: -16,
        },
        {
          id: 2,
          total: 2672,
          percentage: 100,
        },
      ],
      B2BTotalData: [
        {
          id: 1,
          total: 3872,
          percentage: -16,
        },
        {
          id: 2,
          total: 2672,
          percentage: 100,
        },
      ],
      B2CTotalData: [
        {
          id: 1,
          total: 3872,
          percentage: -16,
        },
        {
          id: 2,
          total: 2672,
          percentage: 100,
        },
      ],
      B2GTotalData: [
        {
          id: 1,
          total: 3872,
          percentage: -16,
        },
        {
          id: 2,
          total: 2672,
          percentage: 100,
        },
      ],
      AllData: [
        {
          id: 1,
          title: "B2C",
          total: 1872,
          percentage: -16,
        },
        {
          id: 2,
          title: "B2B",
          total: 1872,
          percentage: 32,
        },
        {
          id: 3,
          title: "B2G",
          total: 1872,
          percentage: 32,
        },
      ],
      B2BData: [
        {
          id: 1,
          title: "Acc. Program",
          total: 1872,
          percentage: -16,
        },
        {
          id: 2,
          title: "Corp. Collaboration",
          total: 1872,
          percentage: 32,
        },
        {
          id: 3,
          title: "Corp. Training",
          total: 1872,
          percentage: 32,
        },
        {
          id: 4,
          title: "Talent As a Service",
          total: 1872,
          percentage: 32,
        },
        {
          id: 5,
          title: "Technology Service",
          total: 1872,
          percentage: 32,
        },
      ],
      B2CData: [
        {
          id: 1,
          title: "Public Training",
          total: 1872,
          percentage: -16,
        },
        {
          id: 2,
          title: "Bootcamp",
          total: 1872,
          percentage: 32,
        },
        {
          id: 3,
          title: "Event",
          total: 1872,
          percentage: 32,
        },
      ],
      B2GData: [
        {
          id: 1,
          title: "Kartu Prakerja",
          total: 1872,
          percentage: -16,
        },
        {
          id: 2,
          title: "Magang",
          total: 1872,
          percentage: 32,
        },
        {
          id: 3,
          title: "Studi Independen",
          total: 1872,
          percentage: 32,
        },
      ],
      AllByMonth: [
        {
          name: "B2C",
          data: [
            1000, 2000, 1500, 3000, 2500, 4000, 3500, 4500, 5000, 5500, 6000,
            6500,
          ],
        },
        {
          name: "B2B",
          data: [
            1100, 2200, 1600, 3100, 2600, 4100, 3600, 4600, 5100, 5600, 6100,
            6600,
          ],
        },
        {
          name: "B2G",
          data: [
            1200, 2300, 1700, 3200, 2700, 4200, 3700, 4700, 5200, 5700, 6200,
            6700,
          ],
        },
      ],
      AllByYear: [
        {
          name: "B2C",
          data: [12000, 15000, 18000, 21000, 24000],
        },
        {
          name: "B2B",
          data: [12500, 15500, 18500, 21500, 24500],
        },
        {
          name: "B2G",
          data: [13000, 16000, 19000, 22000, 25000],
        },
      ],
      B2CByMonth: [
        {
          name: "Public Training",
          data: [
            1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100,
            2200,
          ],
        },
        {
          name: "Bootcamp",
          data: [
            2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3100,
            3200,
          ],
        },
        {
          name: "Event",
          data: [
            3100, 3200, 3300, 3400, 3500, 3600, 3700, 3800, 3900, 4000, 4100,
            4200,
          ],
        },
      ],
      B2CByYear: [
        {
          name: "Public Training",
          data: [13000, 14000, 15000, 16000, 17000],
        },
        {
          name: "Bootcamp",
          data: [23000, 24000, 25000, 26000, 27000],
        },
        {
          name: "Event",
          data: [33000, 34000, 35000, 36000, 37000],
        },
      ],
      B2BByMonth: [
        {
          name: "Acc. Program",
          data: [
            800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,
            1900,
          ],
        },
        {
          name: "Corp. Collaboration",
          data: [
            1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200,
            2300,
          ],
        },
        {
          name: "Corp. Training",
          data: [
            1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000,
            2100,
          ],
        },
        {
          name: "Talent As a Service",
          data: [
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500,
            2600,
          ],
        },
        {
          name: "Technology Service",
          data: [
            900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900,
            2000,
          ],
        },
      ],
      B2BByYear: [
        {
          name: "Acc. Program",
          data: [10000, 11000, 12000, 13000, 14000],
        },
        {
          name: "Corp. Collaboration",
          data: [14000, 15000, 16000, 17000, 18000],
        },
        {
          name: "Corp. Training",
          data: [12000, 13000, 14000, 15000, 16000],
        },
        {
          name: "Talent As a Service",
          data: [17000, 18000, 19000, 20000, 21000],
        },
        {
          name: "Technology Service",
          data: [11000, 12000, 13000, 14000, 15000],
        },
      ],
      B2GByMonth: [
        {
          name: "Kartu Prakerja",
          data: [
            500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600,
          ],
        },
        {
          name: "Magang",
          data: [
            700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800,
          ],
        },
        {
          name: "Studi Independen",
          data: [
            600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700,
          ],
        },
      ],
      B2GByYear: [
        {
          name: "Kartu Prakerja",
          data: [8000, 9000, 10000, 11000, 12000],
        },
        {
          name: "Magang",
          data: [10000, 11000, 12000, 13000, 14000],
        },
        {
          name: "Studi Independen",
          data: [9000, 10000, 11000, 12000, 13000],
        },
      ],
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            click: (event, chartContext, config) => {
              this.updateCurrentIncome(event, chartContext, config);
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 2,
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "last", // 'all', 'last'
            dataLabels: {
              total: {
                enabled: false,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "top",
          offsetY: 0,
          markers: {
            radius: 10,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          strokeDashArray: 4,
          borderColor: "#C9CBCD",
        },
        colors: [
          "#4EA8DE",
          "#48BFE3",
          "#56CFE1",
          "#64DFDF",
          "#72EFDD",
          "#80FFDB",
        ],
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            seriesIndex;
            const data = [];
            const label = [];
            let total = 0;
            let colors = w.globals.colors;
            for (const i in series) {
              data.push(series[i][dataPointIndex]);
              label.push(w.globals.initialSeries[i].name);
            }

            for (const i in data) {
              total += Number(data[i]);
            }

            if (total.toString().length > 3) {
              total = total / 1000;
              total = total.toFixed(0) + "K";
            }

            for (const i in data) {
              if (data[i].toString().length > 3) {
                data[i] = data[i] / 1000;
                data[i] = data[i].toFixed(0) + "K";
              }
            }

            let tooltip = `<v-col style="
            border-radius: 10px;
            font-size= 14px;
            font-weight= 400;
            color= #7B7E8C;
            ">
              <div class="d-flex align-center justify-space-between" style="background-color: #F5F6FA;padding: 8px 16px; gap:30px">
                <div>Total</div>
                <div>${total}</div>
              </div>`;

            for (const i in data) {
              tooltip += `<div class="d-flex align-center justify-space-between" style="background-color: #FFFFFF; padding: 8px 16px;gap: 4px"
              >
                <div class="d-flex align-center" style="gap: 4px">
                  <div
                    style="
                      background-color: ${colors[i]};
                      width: 12px;
                      height: 12px;
                      border-radius: 100%;
                    "
                  >
                  </div>
                  <div>
                  ${label[i]}
                  </div>
                </div>
                <div>${data[i]}</div>
              </div>`;
            }

            tooltip += `</v-col>`;

            return tooltip;
          },
        },
      },
    };
  },
  methods: {
    clickBusinessButton(index) {
      this.indexBusinessButton = index;
      this.getMonthYearChart();
      this.getBusinessCategoryChart();
    },
    clickMonthYearButton(index) {
      this.indexMonthYearButton = index;
      this.getMonthYearChart();
      this.getBusinessCategoryChart();
    },
    getArrowColor(percentage) {
      return percentage <= 0 ? "#FF5656" : "#0EA976";
    },
    getArrowDirect(percentage) {
      return percentage <= 0 ? "down" : "up";
    },
    removeMinSymbol(number) {
      return number.toString().split("-").join(" ");
    },
    formatPrice(price) {
      var format = new Intl.NumberFormat("id", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0,
      })
        .format(price)
        .replace("Rp", "")
        .trim("");

      return format;
    },
    getAllMonths() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    },
    getLast5Years() {
      const currentYear = new Date().getFullYear();
      return [
        currentYear - 4,
        currentYear - 3,
        currentYear - 2,
        currentYear - 1,
        currentYear,
      ];
    },
    getMonthYearChart() {
      if (this.indexMonthYearButton === 0) {
        return this.$refs.realtimeChart.updateOptions({
          xaxis: {
            categories: this.getAllMonths(),
          },
        });
      } else {
        return this.$refs.realtimeChart.updateOptions({
          xaxis: {
            categories: this.getLast5Years(),
          },
        });
      }
    },
    getBusinessCategoryChart() {
      if (this.businessCategory[this.indexBusinessButton] === "B2C") {
        if (this.monthYearCategory[this.indexMonthYearButton] === "Month") {
          this.series = this.B2CByMonth;
        } else {
          this.series = this.B2CByYear;
        }
      } else if (this.businessCategory[this.indexBusinessButton] === "B2B") {
        if (this.monthYearCategory[this.indexMonthYearButton] === "Month") {
          this.series = this.B2BByMonth;
        } else {
          this.series = this.B2BByYear;
        }
      } else if (this.businessCategory[this.indexBusinessButton] === "B2G") {
        if (this.monthYearCategory[this.indexMonthYearButton] === "Month") {
          this.series = this.B2GByMonth;
        } else {
          this.series = this.B2GByYear;
        }
      } else {
        if (this.monthYearCategory[this.indexMonthYearButton] === "Month") {
          this.series = this.AllByMonth;
        } else {
          this.series = this.AllByYear;
        }
      }

      this.$refs.realtimeChart.updateSeries(this.series);
    },
    updateCurrentIncome(event, chartContext, config) {
      console.log(config);
      const data = [];
      let total = 0;
      for (const i in this.series) {
        data.push(this.series[i].data[config.dataPointIndex]);
      }

      for (const i in data) {
        total += Number(data[i]);
      }

      if (total.toString().length > 3) {
        total = total / 1000;
        total = total.toFixed(0) + "K";
      }

      this.currentIncome = {
        month: moment()
          .month(config.dataPointIndex)
          .format("MMMM"),
        total: total,
      };
    },
  },
  computed: {
    displayedData() {
      if (this.businessCategory[this.indexBusinessButton] === "B2C") {
        return this.B2CTotalData;
      } else if (this.businessCategory[this.indexBusinessButton] === "B2B") {
        return this.B2BTotalData;
      } else if (this.businessCategory[this.indexBusinessButton] === "B2G") {
        return this.B2GTotalData;
      }
      return this.AllTotalData;
    },
    displayedClassData() {
      if (this.businessCategory[this.indexBusinessButton] === "B2C") {
        return this.B2CData;
      } else if (this.businessCategory[this.indexBusinessButton] === "B2B") {
        return this.B2BData;
      } else if (this.businessCategory[this.indexBusinessButton] === "B2G") {
        return this.B2GData;
      }
      return this.AllData;
    },
    getCurrentMonth() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const currentDate = new Date();
      return month[currentDate.getMonth()];
    },
  },
  mounted() {
    this.getMonthYearChart();
    this.getBusinessCategoryChart();
  },
};
</script>

<style></style>
